import React from 'react';
import { useTranslation } from 'react-i18next';
import ScrollAnimation from "react-animate-on-scroll";

const ServiceThree = ({ textAlign, serviceStyle }) => {
    const { t } = useTranslation();

    const ServiceList = [
        {
            image: './images/service/specialhospitality.jpg',
            title: t('services.why.title'),
            description: t('services.why.description')
        },
        {
            image: 'https://images.unsplash.com/photo-1445019980597-93fa8acb246c?w=800&auto=format&fit=crop&q=60',
            title: t('services.how.title'),
            description: t('services.how.description')
        },
        {
            image: 'https://images.unsplash.com/photo-1621293954908-907159247fc8?w=800&auto=format&fit=crop&q=60',
            title: t('services.what.title'),
            description: t('services.what.description')
        }
    ];

    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                        delay={i * 100}
                    >
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <img 
                                        src={val.image} 
                                        alt={val.title}
                                        style={{
                                            width: '100%',
                                            height: '250px',
                                            objectFit: 'cover',
                                            borderRadius: '8px'
                                        }}
                                    />
                                </div>
                                <div className="content" style={{ padding: '25px 0' }}>
                                    <h4 className="title" style={{ marginBottom: '15px' }}>
                                        <a dangerouslySetInnerHTML={{ __html: val.title }}></a>
                                    </h4>
                                    <div 
                                        className="description b1 color-gray mb--0" 
                                        dangerouslySetInnerHTML={{ __html: val.description }}
                                        style={{ lineHeight: '1.6' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    );
};

export default ServiceThree;
