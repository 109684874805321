import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from 'react-i18next';

const TimelineOne = () => {
    const { t } = useTranslation();

    const timelineData = [
        {
            title: t("smartHospitality"),
            image: "./images/timeline/smartoda.jpg",
            description: t("smartHospitalityDesc") + 
                "<br/><br/>" +
                "• " + t("aiAssistant") + "<br/>" +
                "• " + t("lightingSystem") + "<br/>" +
                "• " + t("jacuzziControl") + "<br/>" +
                "• " + t("tvSecurity") + "<br/>" +
                "• " + t("cloudComm") + "<br/>" +
                "• " + t("energySensors") + "<br/>" +
                "• " + t("remoteControls"),
            link: "/contact"
        },
        {
            title: t("luxuryExperience"),
            image: "./images/timeline/Luxuryexperience.jpg",
            description: t("luxuryExperienceDesc") +
                "<br/><br/>" +
                "• " + t("directComm") + "<br/>" +
                "• " + t("oneClick") + "<br/>" +
                "• " + t("aiProcess") + "<br/>" +
                "• " + t("efficiency") + "<br/>" +
                "• " + t("targetManagement") + "<br/>" +
                "• " + t("personalizedExp") + "<br/>" +
                "• " + t("globalUse"),
            link: "/contact"
        },
        {
            title: t("sustainableSolutions"),
            image: "./images/timeline/solutions.jpg",
            description: t("sustainableSolutionsDesc") +
                "<br/><br/>" +
                "• " + t("endToEnd") + "<br/>" +
                "• " + t("aiResource") + "<br/>" +
                "• " + t("smartCost") + "<br/>" +
                "• " + t("cloudOps") + "<br/>" +
                "• " + t("personnel"),
            link: "/contact"
        }
    ];

    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>

            {timelineData.map((item, index) => (
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">


                                        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                            <h2 className="title">{item.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                            <p className="description" dangerouslySetInnerHTML={{__html: item.description}}></p>
                                        </ScrollAnimation>

                                        <div className="read-morebtn">
                                            <ScrollAnimation animateIn="fadeInUp" animateOut="fadeInOut" animateOnce={true}>
                                                <a className="btn-default btn-large round" href={item.link} rel="noopener noreferrer">
                                                    <span>{t("contactUs")}</span>
                                                </a>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img 
                                            className="w-100" 
                                            src={item.image} 
                                            alt={item.title}
                                          
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default TimelineOne;
