import React from "react";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import { useTranslation } from 'react-i18next';
import Chat from '../common/chat_query';
import AppScreens from '../components/demo/AppScreens';
const Demo = () => {
    const { t } = useTranslation();

    return (
        <>
            <Chat/>
            <SEO title={t('demo')} />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
                <AppScreens />
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Demo;
