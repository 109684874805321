import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FaEnvelope, FaLock, FaTimes, FaCopy } from 'react-icons/fa';

const DeviceContainer = styled.div`
    position: relative;
    margin-top: 60px;
`;

const InfoButton = styled.button`
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background: linear-gradient(135deg, #2dcecc 0%, #25b5b3 100%);
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    z-index: 5;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0 4px 15px rgba(45, 206, 204, 0.2);

    svg {
        font-size: 16px;
    }

    &:hover {
        transform: translateX(-50%) translateY(-2px);
        box-shadow: 0 6px 20px rgba(45, 206, 204, 0.3);
        background: linear-gradient(135deg, #25b5b3 0%, #2dcecc 100%);
    }

    &:active {
        transform: translateX(-50%) translateY(-1px);
        box-shadow: 0 4px 15px rgba(45, 206, 204, 0.2);
    }
`;

const InfoPopup = styled.div`
    position: absolute;
    top: -140px;
    left: 50%;
    transform: translate(-50%, 10px);
    background: white;
    border: 1px solid rgba(45, 206, 204, 0.1);
    border-radius: 10px;
    padding: 16px;
    font-size: 13px;
    color: #333;
    text-align: left;
    opacity: ${props => props.isVisible ? 1 : 0};
    visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
    transition: all 0.3s ease;
    z-index: 6;
    box-shadow: 0 6px 16px rgba(0,0,0,0.06);
    min-width: 340px;
    max-width: 380px;
    backdrop-filter: blur(10px);

    .credential-row {
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 6px 0;
        padding: 10px 12px;
        background: rgba(45, 206, 204, 0.05);
        border-radius: 6px;
        position: relative;
        transition: all 0.3s ease;
        
        &:hover {
            background: rgba(45, 206, 204, 0.1);
        }

        svg.icon {
            color: #2dcecc;
            width: 14px;
            min-width: 14px;
            margin-right: 2px;
        }

        .credential-text {
            flex: 1;
            font-family: monospace;
            font-size: 12px;
            color: #444;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 4px;
        }

        .copy-button {
            background: none;
            border: none;
            cursor: pointer;
            padding: 6px;
            color: #999;
            transition: all 0.2s ease;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                color: #2dcecc;
                background: rgba(45, 206, 204, 0.1);
                transform: scale(1.1);
            }

            &:active {
                transform: scale(0.95);
            }

            svg {
                width: 14px;
                height: 14px;
            }
        }

        .copy-success {
            position: absolute;
            right: -10px;
            top: -20px;
            background: #2dcecc;
            color: white;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 12px;
            opacity: 0;
            transform: translateY(10px);
            transition: all 0.3s ease;
            box-shadow: 0 2px 8px rgba(45, 206, 204, 0.2);

            &.show {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    hr {
        margin: 10px 0;
        border: none;
        border-top: 1px dashed rgba(45, 206, 204, 0.2);
    }

    strong {
        color: #2dcecc;
        display: block;
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }

    .close-button {
        position: absolute;
        top: 8px;
        right: 8px;
        background: none;
        border: none;
        cursor: pointer;
        padding: 6px;
        color: #999;
        transition: all 0.2s ease;
        border-radius: 4px;

        &:hover {
            color: #2dcecc;
            background: rgba(45, 206, 204, 0.1);
        }

        svg {
            width: 12px;
        }
    }
`;

const DeviceFrame = styled.div`
    position: relative;
    width: 330px;
    height: 680px;
    margin: 0 auto;
    border-radius: 45px;
    background: #1a1a1a;
    padding: 2px;
    box-shadow: 
        0 50px 100px -20px rgba(50, 50, 93, 0.12),
        0 30px 60px -30px rgba(0, 0, 0, 0.15),
        inset 0 -2px 6px 0 rgba(10, 37, 64, 0.2);
    border: 0.5px solid #424242;
    overflow: hidden;

    .notch {
        position: absolute;
        width: 90px;
        height: 25px;
        background: #1a1a1a;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px;
        z-index: 2;

        &:before {
            content: '';
            position: absolute;
            top: 7px;
            left: 50%;
            transform: translateX(-50%);
            width: 32px;
            height: 3px;
            background: #333;
            border-radius: 20px;
        }
    }

    .button {
        position: absolute;
        right: -2px;
        top: 120px;
        width: 3px;
        height: 30px;
        background: #2c2c2c;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        
        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 60px;
            width: 3px;
            height: 60px;
            background: #2c2c2c;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }
        
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 140px;
            width: 3px;
            height: 60px;
            background: #2c2c2c;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }
    }

    .iframe-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 42px;
        background: #fff;
    }

    .app-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 440px;
        height: 900px;
        border: none;
        transform: scale(0.75);
        transform-origin: 0 0;
    }
`;

const DeviceTitle = styled.h3`    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
`;

const AppStoreButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 20px;

    .store-button {
        img {
            height: 50px;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.05);
            }
        }
    }
`;

const AppScreens = () => {
    const { t } = useTranslation();
    const [showPopup, setShowPopup] = useState({
        guest: false,
        backoffice: false,
        dashboard: false
    });
    const [copySuccess, setCopySuccess] = useState({});

    const credentials = {
        guest: {
            reservationCode: "DEMO1001",
        },
        backoffice: {
            accounts: [
                {
                    email: "demo-admin2025@sumtech.io"
                },
                {
                    email: "demo_account-2-2025@sumtech.io"
                },
                {
                    email: "demo_account2025@sumtech.io"
                }
            ]
        },
        dashboard1: {
            username: "john_doe@example.com",
            password: "demoPassword123",
        },
        dashboard2:{
            username: "johnny_smith@example.com",
            password: "demoPassword123",
        }
    };

    const copyToClipboard = (text, id) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopySuccess({[id]: true});
            setTimeout(() => {
                setCopySuccess({});
            }, 2000);
        });
    };

    return (
        <ScreensWrapper>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 mb-4">
                        <DeviceContainer>
                            <InfoButton onClick={() => setShowPopup(prev => ({...prev, guest: !prev.guest}))}>
                                {t('demo.loginInfo')}
                            </InfoButton>
                            <InfoPopup isVisible={showPopup.guest}>
                                <button 
                                    className="close-button"
                                    onClick={() => setShowPopup(prev => ({...prev, guest: false}))}
                                >
                                    <FaTimes />
                                </button>
                                <strong>{t('demo.guestPanel')}</strong>
                                <div className="credential-row">
                                    <FaEnvelope className="icon" />
                                    <span className="credential-text">{credentials.guest.reservationCode}</span>
                                    <button 
                                        className="copy-button" 
                                        onClick={() => copyToClipboard(credentials.guest.reservationCode, 'guest')}
                                        title="Copy to clipboard"
                                    >
                                        <FaCopy />
                                    </button>
                                    {copySuccess['guest'] && (
                                        <span className="copy-success show">Kopyalandı!</span>
                                    )}
                                </div>
                            </InfoPopup>
                            <DeviceFrame>
                                <div className="notch"></div>
                                <div className="iframe-wrapper">
                                    <iframe
                                        src="https://guest-panel.vercel.app"
                                        title="Guest Panel"
                                        className="app-iframe"
                                        sandbox="allow-same-origin allow-scripts"
                                    />
                                </div>
                                <div className="button"></div>
                            </DeviceFrame>
                        </DeviceContainer>
                        <DeviceTitle>Guest Panel</DeviceTitle>
                        <AppStoreButtons>
                            <a href="https://apps.apple.com/tr/app/bestill-experience/id6739133952" target="_blank" rel="noopener noreferrer" className="store-button">
                                <img src="/images/app-store.png" alt="App Store" />
                            </a>
                            <a href="https://play.google.com/guest" target="_blank" rel="noopener noreferrer" className="store-button">
                                <img src="/images/google-play.png" alt="Google Play" />
                            </a>
                        </AppStoreButtons>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <DeviceContainer>
                            <InfoButton onClick={() => setShowPopup(prev => ({...prev, backoffice: !prev.backoffice}))}>
                                {t('demo.loginInfo')}
                            </InfoButton>
                            <InfoPopup isVisible={showPopup.backoffice}>
                                <button 
                                    className="close-button"
                                    onClick={() => setShowPopup(prev => ({...prev, backoffice: false}))}>
                                    <FaTimes />
                                </button>
                                <strong>{t('demo.backOffice')}</strong>
                                {credentials.backoffice.accounts.map((account, index) => (
                                    <div className="credential-row">
                                        <FaEnvelope className="icon" />
                                        <span className="credential-text">{account.email}</span>
                                        <button 
                                            className="copy-button" 
                                            onClick={() => copyToClipboard(account.email, `backoffice-${index}`)}
                                            title="Copy to clipboard">
                                            <FaCopy />
                                        </button>
                                        {copySuccess[`backoffice-${index}`] && (
                                            <span className="copy-success show">Kopyalandı!</span>
                                        )}
                                    </div>
                                ))}
                            </InfoPopup>
                            <DeviceFrame>
                                <div className="notch"></div>
                                <div className="iframe-wrapper">
                                    <iframe
                                        src="https://vista-backoffice-app-git-web-summit-tech-s-team.vercel.app"
                                        title="Back Office"
                                        className="app-iframe"
                                    />
                                </div>
                                <div className="button"></div>
                            </DeviceFrame>
                        </DeviceContainer>
                        <DeviceTitle>Back Office</DeviceTitle>
                        <AppStoreButtons>
                            <a href="https://apps.apple.com/tr/app/summit-vista/id6496853906" target="_blank" rel="noopener noreferrer" className="store-button">
                                <img src="/images/app-store.png" alt="App Store" />
                            </a>
                            <a href="https://play.google.com/backoffice" target="_blank" rel="noopener noreferrer" className="store-button">
                                <img src="/images/google-play.png" alt="Google Play" />
                            </a>
                        </AppStoreButtons>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <DeviceContainer>
                            <InfoButton onClick={() => setShowPopup(prev => ({...prev, dashboard: !prev.dashboard}))}>
                                {t('demo.loginInfo')}
                            </InfoButton>
                            <InfoPopup isVisible={showPopup.dashboard}>
                                <button 
                                    className="close-button"
                                    onClick={() => setShowPopup(prev => ({...prev, dashboard: false}))}
                                >
                                    <FaTimes />
                                </button>
                                <strong>{t('demo.dashboard')}</strong>
                                <div className="credential-row">
                                    <FaEnvelope className="icon" />
                                    <span className="credential-text">{credentials.dashboard1.username}</span>
                                    <button 
                                        className="copy-button" 
                                        onClick={() => copyToClipboard(credentials.dashboard1.username, 'dashboard1-username')}
                                        title="Copy to clipboard"
                                    >
                                        <FaCopy />
                                    </button>
                                    {copySuccess['dashboard1-username'] && (
                                        <span className="copy-success show">Kopyalandı!</span>
                                    )}
                                </div>
                                <div className="credential-row">
                                    <FaLock className="icon" />
                                    <span className="credential-text">{credentials.dashboard1.password}</span>
                                    <button 
                                        className="copy-button" 
                                        onClick={() => copyToClipboard(credentials.dashboard1.password, 'dashboard1-password')}
                                        title="Copy to clipboard"
                                    >
                                        <FaCopy />
                                    </button>
                                    {copySuccess['dashboard1-password'] && (
                                        <span className="copy-success show">Kopyalandı!</span>
                                    )}
                                </div>
                                <hr />
                                <div className="credential-row">
                                    <FaEnvelope className="icon" />
                                    <span className="credential-text">{credentials.dashboard2.username}</span>
                                    <button 
                                        className="copy-button" 
                                        onClick={() => copyToClipboard(credentials.dashboard2.username, 'dashboard2-username')}
                                        title="Copy to clipboard"
                                    >
                                        <FaCopy />
                                    </button>
                                    {copySuccess['dashboard2-username'] && (
                                        <span className="copy-success show">Kopyalandı!</span>
                                    )}
                                </div>
                                <div className="credential-row">
                                    <FaLock className="icon" />
                                    <span className="credential-text">{credentials.dashboard2.password}</span>
                                    <button 
                                        className="copy-button" 
                                        onClick={() => copyToClipboard(credentials.dashboard2.password, 'dashboard2-password')}
                                        title="Copy to clipboard"
                                    >
                                        <FaCopy />
                                    </button>
                                    {copySuccess['dashboard2-password'] && (
                                        <span className="copy-success show">Kopyalandı!</span>
                                    )}
                                </div>
                            </InfoPopup>
                            <DeviceFrame>
                                <div className="notch"></div>
                                <div className="iframe-wrapper">
                                    <iframe
                                        src="https://dashboard.specialhospitality.com"
                                        title="Dashboard"
                                        className="app-iframe"
                                    />
                                </div>
                                <div className="button"></div>
                            </DeviceFrame>
                        </DeviceContainer>
                        <DeviceTitle>Dashboard</DeviceTitle>
                        <AppStoreButtons>
                            <a href="https://apps.apple.com/tr/app/summit-vista/id6496853906" target="_blank" rel="noopener noreferrer" className="store-button">
                                <img src="/images/app-store.png" alt="App Store" />
                            </a>
                            <a href="https://play.google.com/dashboard" target="_blank" rel="noopener noreferrer" className="store-button">
                                <img src="/images/google-play.png" alt="Google Play" />
                            </a>
                        </AppStoreButtons>
                    </div>
                </div>
            </div>
        </ScreensWrapper>
    );
};

const ScreensWrapper = styled.div`
    padding: 200px 0;
    background: #f8f9fc;
`;

export default AppScreens;

