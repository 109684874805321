import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionTitle from "../sectionTitle/SectionTitle";

const AppShowcase = () => {
    const { t } = useTranslation();

    return (
        <div className="rwt-app-showcase-area rn-section-gap">
            <div className="container">
                <div className="row mb--40">
                    <div className="col-lg-12">
                        <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            subtitle={t('MobileApp')}
                            title={t('DiscoverOurApp')}
                            description={t('MobileAppDesc')}
                        />
                    </div>
                </div>

                <div className="row row--30 align-items-center justify-content-center">
                    <div className="col-lg-6 mt--30">
                        <div className="content">
                            <div className="inner">
                                <div className="experience-text">
                                    <p className="description">
                                        {t('AppExperience')}
                                    </p>
                                </div>

                                <div className="button-group mt--30">
                                    <a href="https://vista-landing-page.sumtech.io/"
                                        className="btn-default btn-large round"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {t('LearnMore')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 mt--30">
                        <div className="thumbnail">
                            <img
                                src="/images/app2.png"
                                alt="App Showcase"
                                className="w-100"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppShowcase; 