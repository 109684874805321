import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // i18n hook
import appStore from '../../store/appStore';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { observer } from 'mobx-react'; // MobX observer
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = observer(({ formStyle }) => { // observer ile sarmala
    const { t } = useTranslation(); // i18n hook
    const [result, showResult] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        email_name: 'Summit Vista',
    });

    const onRecaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const sendEmail = async (e) => {
        e.preventDefault();

        if (!captchaToken) {
            toast.error(t("please_complete_recaptcha"));
            return;
        }
        const response = await appStore.sendContact({ ...formData, captchaToken });

        if (response.success) {
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }

        showResult(true);

        setTimeout(() => {
            showResult(false);
        }, 5000);

        setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            email_name: 'Summit Vista',
            captchaToken: '',
        });
    };

    return (
        <>
            <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
                <div className="form-group">
                    <input
                        type="text"
                        name="name"
                        placeholder={t("your_name")} // Çeviri eklenmiş
                        required
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>

                <div className="form-group">
                    <input
                        type="email"
                        name="email"
                        placeholder={t("email_address")} // Çeviri eklenmiş
                        required
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>

                <div className="form-group">
                    <input
                        type="text"
                        name="phone"
                        placeholder={t("phone_number")} // Çeviri eklenmiş
                        required
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </div>

                <div className="form-group">
                    <input
                        type="text"
                        name="subject"
                        placeholder={t("subject")} // Çeviri eklenmiş
                        required
                        value={formData.subject}
                        onChange={handleChange}
                    />
                </div>

                <div className="form-group">
                    <textarea
                        name="message"
                        placeholder={t("your_message")} // Çeviri eklenmiş
                        required
                        value={formData.message}
                        onChange={handleChange}
                    >
                    </textarea>
                </div>

                <div className="form-group">
                    <ReCAPTCHA
                        sitekey="6LfSUd4qAAAAAKjz_C1MGO1B-VhX27uGPNLnfWeM"
                        onChange={onRecaptchaChange}
                    />
                </div>
                <div className="form-group">
                    <button 
                        className="btn-default btn-large" 
                        disabled={!captchaToken}
                    >
                        {t("submit_now")}
                    </button>
                </div>
            </form>

            <ToastContainer />
        </>
    );
});

export default ContactForm;
