import React from 'react';
import Typed from 'react-typed';
import { useTranslation } from 'react-i18next';

const AboutOne = () => {
    const { t } = useTranslation();
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">

                    <div className="col-lg-6">
                        <div className="thumbnail">
                            <img 
                                className="w-100" 
                                src="./images/about/bestill.jpeg" 
                                alt={t('Istanbul')} 
                                style={{
                                    maxHeight: '650px',
                                    objectFit: 'cover',
                                    borderRadius: '10px'
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title" style={{ textAlign: "center" }}>{" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Nevşehir",
                                            "Capadoccia",
                                            "Ortahisar",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>{t('bestilldesc1')}</p>
                                <p>{t('bestilldesc2')}</p>
                                <div className="view-more-button mt--35">
                                    <a className="btn-default" href="https://bestillhotel.com/">{t('visitbestill')}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AboutOne;
