import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionTitle from "../sectionTitle/SectionTitle";
import { FiCheckCircle } from "react-icons/fi";
import { FaAppStore } from "react-icons/fa";

const AdvanceTabTwo = () => {
    const { t } = useTranslation();

    return (
        <div >
            <div className="container">
                <div className="row mb--15">
                    <div className="col-lg-12">
                        <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            title={t("BackOfficeApps")}
                            description={t("BackOfficeDesc")}
                        />
                    </div>
                </div>

                <div className="content-wrapper" style={{
                    background: '#fff',
                    borderRadius: '15px',
                    boxShadow: '0 5px 25px rgba(0,0,0,0.08)',
                    padding: '25px'
                }}>
                    <div className="row row--20 align-items-center">
                        {/* Sol taraf - Fotoğraf */}
                        <div className="col-lg-7 col-md-12 col-12">
                            <div className="rn-split-wrapper">
                                <div className="thumbnail image-left-content" style={{ position: 'relative' }}>
                                    <img 
                                        src="./images/tab/backoffice.png" 
                                        alt="Back Office Apps"
                                        style={{
                                            borderRadius: '10px',
                                            boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                                            width: '100%',
                                            height: 'auto',
                                            marginBottom: '15px'
                                        }}
                                    />
                                    {/* App Store Link */}
                                    <a 
                                        href="https://apps.apple.com/tr/app/vista-backoffice/id6740934883?l=tr" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        style={{
                                            position: 'absolute',
                                            bottom: '30px',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            padding: '12px 25px',
                                            background: 'rgba(0, 0, 0, 0.8)',
                                            color: '#fff',
                                            borderRadius: '25px',
                                            textDecoration: 'none',
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            transition: 'all 0.3s ease',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px'
                                        }}
                                        onMouseOver={(e) => e.target.style.background = 'rgba(0, 0, 0, 0.9)'}
                                        onMouseOut={(e) => e.target.style.background = 'rgba(0, 0, 0, 0.8)'}
                                    >
                                        <FaAppStore size={22} />
                                        {t('appstorebo')}
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Sağ taraf - İçerik */}
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="split-inner">
                                <div className="feature-list">
                                    <div className="feature-box mb--10" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <div className="icon color-primary" style={{ marginRight: '10px', marginTop: '3px' }}>
                                            <FiCheckCircle size={20} />
                                        </div>
                                        <div className="content">
                                            <h4 className="title" style={{ fontSize: '17px', marginBottom: '4px', color: '#1f1f25' }}>{t("TaskManagement")}</h4>
                                            <p className="description" style={{ fontSize: '13px', lineHeight: '1.3', color: '#525260' }}>
                                                {t("TaskManagementDesc")}
                                            </p>
                                        </div>
                                    </div>
                                    
                                    <div className="feature-box mb--10" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <div className="icon color-primary" style={{ marginRight: '10px', marginTop: '3px' }}>
                                            <FiCheckCircle size={20} />
                                        </div>
                                        <div className="content">
                                            <h4 className="title" style={{ fontSize: '17px', marginBottom: '4px', color: '#1f1f25' }}>{t("StaffManagement")}</h4>
                                            <p className="description" style={{ fontSize: '13px', lineHeight: '1.3', color: '#525260' }}>
                                                {t("StaffManagementDesc")}
                                            </p>
                                        </div>
                                    </div>
                                    
                                    <div className="feature-box mb--10" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <div className="icon color-primary" style={{ marginRight: '10px', marginTop: '3px' }}>
                                            <FiCheckCircle size={20} />
                                        </div>
                                        <div className="content">
                                            <h4 className="title" style={{ fontSize: '17px', marginBottom: '4px', color: '#1f1f25' }}>{t("Analytics")}</h4>
                                            <p className="description" style={{ fontSize: '13px', lineHeight: '1.3', color: '#525260' }}>
                                                {t("AnalyticsDesc")}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="feature-box" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <div className="icon color-primary" style={{ marginRight: '10px', marginTop: '3px' }}>
                                            <FiCheckCircle size={20} />
                                        </div>
                                        <div className="content">
                                            <h4 className="title" style={{ fontSize: '17px', marginBottom: '4px', color: '#1f1f25' }}>{t("Notifications")}</h4>
                                            <p className="description" style={{ fontSize: '13px', lineHeight: '1.3', color: '#525260' }}>
                                                {t("NotificationsDesc")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdvanceTabTwo;
