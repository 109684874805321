import React from 'react';
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import BrandThree from '../elements/brand/BrandThree';
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';
import AdvanceTabTwo from '../elements/advancetab/AdvanceTabTwo';
import { FiArrowRight } from "react-icons/fi";
import Chat from '../common/chat_query';
import { useTranslation } from 'react-i18next';
import CalltoActionNine from "../elements/calltoaction/CalltoActionNine";
const EventConference = () => {
    const { t } = useTranslation();
    return (
        <>
            <SEO title="Monetize Your Property" />
            <Chat/>
            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-left inheader-transparent height-650">
                    <div className="container">
                        <div className="row row--20 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--30 mt_sm--30 mt_lg--20">
                                <div className="inner text-start">
                                    <h1 className="title color-white" style={{fontSize: "3.5rem"}}>{t('MaximizeRevenue')}</h1>
                                    <p className="description color-white">{t('MaximizeDesc')}</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border" target="_blank" href="/contact">
                                            {t('ContactUs')} <i className="icon"><FiArrowRight /></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/owner.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                textAlign="text-center"
                                subtitle={t('ManagingProperties')}
                                title={t('EmpowerOwnership')}
                            />
                        </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}
                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                        <div className="col-lg-12">
                            <SectionTitle
                                textAlign="text-center"
                                radiusRounded=""
                                title={t("ownerpanel")} 
                                description={t("navigate_support_materials")}    
                            />
                        </div>
                        </div>
                        <AdvanceTabOne />                     
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator />

                {/* Start Elements Area  */}
                <div style={{ marginBottom: '80px' }}>
                    <AdvanceTabTwo />
                </div>
                {/* End Elements Area  */}

                <Separator />

                {/* Start Call to Action Area */}
                <div style={{ marginBottom: '80px' }}>
                    <CalltoActionNine />
                </div>
                {/* End Call to Action Area */}

                {/* Start Brand Area  */}
                <div className="rwt-brand-area ptb--90">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default EventConference;